import { Injectable } from '@angular/core';
import { User } from '../biz/User';
import * as bcrypt from 'bcryptjs';
import { SecurityService } from './security.service';
import { ILoginRequest } from './structure-http-interface/ILogin/ILoginRequest';
import { HttpService } from './http.service';
import { DummyService } from './dummy.service';
import { ILoginResponse } from './structure-http-interface/ILogin/ILoginResponse';
import { ProyectFull, ProyectService } from './proyect.service';
import { MenuService } from './menu.service';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { StaticDataService } from './static-data.service';
import { IRolesRequest } from './structure-http-interface/IRol/IRolesRequest';
import { IRolesItems, IRolesResponse } from './structure-http-interface/IRol/IRolesResponse';
import { ICreateUserRequest } from './structure-http-interface/IUsuarios/ICreateUserRequest';
import { ICreateUserResponse } from './structure-http-interface/IUsuarios/ICreateUserResponse';
import { IViewUsersRequest } from './structure-http-interface/IUsuarios/IViewUsersRequest';
import { IViewUsersResponse, IViewUsersResponseItem } from './structure-http-interface/IUsuarios/IViewUsersResponse';
import { ICreateRolRequest } from './structure-http-interface/IRol/ICreateRolRequest';
import { ICreateRolResponse, ICreateRolItems } from './structure-http-interface/IRol/ICreateRolResponse';
import { ICreatePermisosRequest,ICreatePermisosDetRequest } from './structure-http-interface/IRol/ICreatePermisosRequest';
import { ICreatePermisosResponse } from './structure-http-interface/IRol/ICreatePermisosResponse';
import { IGetPermisosRequest } from './structure-http-interface/IRol/IGetPermisosRequest';
import { IGetPermisosResponse, IPermisosItems } from './structure-http-interface/IRol/IGetPermisosResponse';
import { IInhaUsuarioRequest } from './structure-http-interface/IUsuarios/IInhaUsuarioRequest';
import { IInhaUsuariosResponse } from './structure-http-interface/IUsuarios/IInhaUsuariosResponse';
import { IInhaRolRequest } from './structure-http-interface/IRol/IInhaRolRequest';
import { IInhaRolResponse } from './structure-http-interface/IRol/IInhaRolResponse';
import { IEditUsuarioRequest } from './structure-http-interface/IUsuarios/IEditUsuarioRequest';
import { IProyectResponseProyect } from './structure-http-interface/IProyect/IProyectoResponse';
import { IEditRolesResponse } from './structure-http-interface/IRol/IEditRolesResponse';
import { IEditRolesRequest } from './structure-http-interface/IRol/IEditRolesRequest';



  /**
   * 
   */
export enum ROLES_KEYS{
    ROLES = "roles",
    USUARIOS = "usuarios"
 }

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  userInSession:User = null;
  isAuth:boolean;
  public subscribeSession:Subscriber<any>;
  public subscriptionSession:Subscription;
  public Rol:ICreateRolResponse;
  public Permiso:ICreatePermisosResponse;
  public permisosList:IGetPermisosResponse;


  constructor(
    private securityService:SecurityService,
    private httpService:HttpService,
    private proyectService:ProyectService,
    private menuService:MenuService,
    private staticDataService:StaticDataService,
    public nav:Router
    //private dummyService:DummyService
    ) { 
      this.httpService.AuthService = this;
      //this.setSession(null).then(()=>null);
    }

  /**
   * 
   * @param username 
   * @param email 
   * @param password 
   */

  
  public async authenticate(username:string,email:string,password:string,callbackErr:any):Promise<void>{
    let user:ILoginResponse;
    try{
      let salt = this.securityService.getSaltPassword();
      //let passwordHash = await bcrypt.hash(password,salt);
      let passwordHash = btoa(password);
      let request: ILoginRequest = this.httpService.getRequestLogin();
      //let user:ILoginResponse =  await this.dummyService.getDummyUser();
      user = await  this.httpService.doPostHttp<ILoginResponse>("/login", request, {
        "usuario":username,
        "pass":passwordHash
      });
      this.userInSession = new User(user);
      this.userInSession.decorator = user;
      await this.menuService.loadMenu(this.userInSession);
      this.httpService.putValueIntoBodyDefault("usuario",this.userInSession.username);
      await this.proyectService.loadProyects2(this.userInSession.clientId);
      await this.loadStaticData(); 
      await this.setSession(this.userInSession);
    }catch(e){
      if(user)
        callbackErr(user.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /**
   * 
   */
  public async saveUser(id:number,nombre:string,correo:string,tlf:number,password:string,callbackErr:any,callbackSuccess:any):Promise<void>{
    let user:ICreateUserResponse;
    let id_cliente:string | number = this.userInSession.clientId;
    let passwordHash = btoa(password);
    try{

      let request: ICreateUserRequest = this.httpService.getRequestLogin();
      user = await  this.httpService.doPostHttp<ICreateUserResponse>("/createusuarios", request, {
        "nombre":nombre,
        "correo":correo,
        "tlf":tlf,
        "password":passwordHash,
        "rol":id,
        "id":id,
        "id_cliente":id_cliente
      });

      if(user.error){
        callbackErr(user.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(user)
        callbackErr(user.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
  /**Edit Usuario */
  public async editUser(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
    let user:ICreateUserResponse;
    let passwordHash = btoa(body.password);
    try{

      let request: IEditUsuarioRequest = this.httpService.editRequestUsuario();
      user = await  this.httpService.doPostHttp<ICreateUserResponse>("/editarusuario", request, {
        "nombre":body.nombre,
        "correo":body.correo,
        "tlf":body.tlf,
        "password":passwordHash,
        "id_rol":body.id_rol,
        "id":body.id
      });

      if(user.error){
        callbackErr(user.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(user)
        callbackErr(user.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
    /**Edit Rol */
    public async editRol(body:any,callbackErr:any,callbackSuccess:any):Promise<void>{
      let rol:IEditRolesResponse;
      try{
  
        let request: IEditRolesRequest = this.httpService.editRoles();
        rol = await  this.httpService.doPostHttp<IEditRolesResponse>("/editarRoles", request, body);
  
        if(rol.error){
          callbackErr(rol.error);
        }else{
          callbackSuccess();
        }
  
      }catch(e){
        if(rol)
          callbackErr(rol.error);
        else
        callbackErr("Ha ocurrido un error inesperado");
      }
    }
  /**
   * 
   */
  public async saveRol(descripcion:string,callbackErr:any,callbackSuccess:any):Promise<void>{
    
    let rol:ICreateRolResponse;
    let id_cliente:string | number = this.userInSession.clientId;
    try{

      let request: ICreateRolRequest = this.httpService.getRequestLogin();
      rol = await  this.httpService.doPostHttp<ICreateRolResponse>("/createroles", request, {
        "descripcion":descripcion,
        "id_cliente":id_cliente
      });
      this.Rol = rol;

      if(rol.error){
        callbackErr(rol.error);
      }else{

        callbackSuccess();
      }

    }catch(e){
      if(rol)
        callbackErr(rol.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }
    /**
   * 
   */
    public async savePermisos(per_request,callbackErr:any,callbackSuccess:any):Promise<void>{
    
      let per:ICreatePermisosDetRequest;
      try{
  
        let request: ICreatePermisosRequest = this.httpService.getRequesPermisos();
        per = await  this.httpService.doPostHttp<ICreatePermisosResponse>("/createpermisos", request, {
          "permiso":per_request
        });
        this.Permiso = per;
  
        if(per.error){
          callbackErr(per.error);
        }else{
          callbackSuccess();
        }
  
      }catch(e){
        if(per)
          callbackErr("per.");
        else
        callbackErr("Ha ocurrido un error inesperado");
      }
    }
  /**
   * 
   */
  public async loadStaticData(){
    if(this.staticDataService.currencies === null)
        await this.staticDataService.loadCurrency();
    if(this.staticDataService.proyectTypeList === null)
      await this.staticDataService.loadProyectType();
    if(this.staticDataService.countries === null)
      await this.staticDataService.loadCountries();
  }


  /**
   * 
   */
  public async setSession(user:User):Promise<void>{
    await Promise.resolve();
    this.isAuth = (this.userInSession !== null);
    //this.subscribeSession.next(this.isAuth);
    console.log(this.subscribeSession);
    localStorage.setItem("user",JSON.stringify(this.userInSession));
  }

  /**
   * 
   * @returns 
   */
  public observerSession():Observable<any>{
    let self = this;
    return new Observable((observer)=>{
      self.subscribeSession = observer;
      let userJson:any = localStorage.getItem("user");
      if(userJson){
        let user:User =  new User(JSON.parse(userJson)._decorator);
        self.userInSession = user;
        self.setSession(this.userInSession);
      }
      observer.next(self.userInSession !== null);
    });
  }


  /**
   * 
   */
  public logout():void{
    localStorage.removeItem("user");
    this.userInSession = null;
    this.setSession(this.userInSession);
    this.subscribeSession.next(false);
  }

  /**
   * 
   * @param haveSession 
   */
  callbackLazySession(haveSession) {
    if(haveSession){
      let proyects:IProyectResponseProyect[] = [];
      this.proyectService.getProyectsFromMemory().forEach(p => proyects.push(p.proyect));
      this.proyectService.proyects  = proyects;
      let menu = JSON.parse(localStorage.getItem("menu"));
      this.menuService.buildMenu(menu,this.userInSession);
      this.nav.navigate(["/pages/site/dashboard"]).then(()=>null);
    }else{
      this.nav.navigate(["/pages/site/login"]).then(()=>null);
    }
  }

  
  /**
   * 
   */
  public async loadProyectRol():Promise<void>{
    let id_cliente:string | number = this.userInSession.clientId;
    let body:IRolesRequest = {"id_cliente":id_cliente};
    let request: IRolesRequest = this.httpService.getRequestRoles();
    let rolList: IRolesResponse = await  this.httpService.doPostHttp<IRolesResponse>("/getroles", request,body);
    localStorage.setItem(ROLES_KEYS.ROLES,JSON.stringify(rolList.roles));
  }
    /**
   * 
   */
    public get rolList():IRolesItems[] | null{
      return JSON.parse(localStorage.getItem(ROLES_KEYS.ROLES));
    }
  /**
   * 
   */
  public async loadUsuariosCliente(id_proyecto?:number):Promise<void>{
    let id_cliente:string | number = this.userInSession.clientId;
    let body:IViewUsersRequest;
    if(id_proyecto != null && id_proyecto != 0){
      body = {"id_cliente":id_cliente,
        "id_proyecto":id_proyecto};
    }else{
      body = {"id_cliente":id_cliente};
    }

    let request: IViewUsersRequest = this.httpService.getRequestRoles();
    let userLists: IViewUsersResponse = await  this.httpService.doPostHttp<IViewUsersResponse>("/getusuarios", request,body);
    localStorage.setItem(ROLES_KEYS.USUARIOS,JSON.stringify(userLists.usuarios));
  }
  /**
   * 
   */
  public get userLists():IViewUsersResponseItem[] | null{
    return JSON.parse(localStorage.getItem(ROLES_KEYS.USUARIOS));
  }
  /**
   * 
   */
  public async loadPermisos(id_rol:number):Promise<void>{
    let request: IGetPermisosRequest= this.httpService.getRequestPermisosList();
    this.permisosList = await  this.httpService.doPostHttp<IGetPermisosResponse>("/getpermisos", request, {"id_rol":id_rol});
  }
  /**
   * 
   */
      public async inhabilitarUsuario(usuario1:number,callbackErr:any,callbackSuccess:any):Promise<void>{
        let usuario2:IInhaUsuariosResponse;
        let body = {"usuario1":usuario1};
        try{
          let request: IInhaUsuarioRequest = this.httpService.getRequestInhaUsuario();
          usuario2 = await  this.httpService.doPostHttp<IInhaUsuariosResponse>("/inhabilitar_usuario", request,body);
    
          if(usuario2.error){
            callbackErr(usuario2.error);
          }else{
            callbackSuccess();
          }
    
        }catch(e){
          if(usuario2)
            callbackErr(usuario2.error);
          else
          callbackErr("Ha ocurrido un error inesperado");
        }
      }
  /**
   * 
   */
  public async inhabilitarRol(id:number,callbackErr:any,callbackSuccess:any):Promise<void>{
    let rol:IInhaRolResponse;
    let body = {"id":id};
    try{
      let request: IInhaRolResponse = this.httpService.getRequestInhaRol();
      rol = await  this.httpService.doPostHttp<IInhaUsuariosResponse>("/inhabilitar_roles", request,body);

      if(rol.error){
        callbackErr(rol.error);
      }else{
        callbackSuccess();
      }

    }catch(e){
      if(rol)
        callbackErr(rol.error);
      else
      callbackErr("Ha ocurrido un error inesperado");
    }
  }

}
