<div class="header-container">
  <div class="logo-container">
    <a class="logo logo-text" href="#"><h2 class="text-primary">
      <span class="logo-first">Pr</span>
      <img  src="../../../../assets/images/settings-icon.png" alt="">
      <span class="logo-second">j</span>
      <span class="logo-three">ec</span>
      <span class="logo-four">ts</span>
    </h2></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <button  nbButton hero outline [status]="'info'"  (click)="logout()">
        Cerrar Sesion
    </button>
    </nb-action>
  </nb-actions>
</div>
