import { Injectable } from '@angular/core';
import { Menu } from '../biz/Menu';
import { HttpService } from './http.service';
import { DummyService } from './dummy.service';
import { IMenuRequest } from './structure-http-interface/IMenu/IMenuRequest';
import { IMenuResponse, IMenuResponseItems } from './structure-http-interface/IMenu/IMenuResponse';
import { User } from '../biz/User';
import { ILoginResponse, IloginResponsePermissions } from './structure-http-interface/ILogin/ILoginResponse';
import { NbMenuItem } from '@nebular/theme';

/**
 * 
 */
enum NAME_MENU_ITEM{
  PROY = "PROYECTOS",
  CRON = "CRONOGRAMA",
  REC = "RECURSOS",
  FER = "FERIADOS",
  USU = "USUARIOS",
  ADM = "ADMINISTRACION DE HORAS",
  CLI = "CLIENTES",
  ACT = "ACTIVIDADES",
  ITE = "ITERACIONES",
  FLU = "FLUJO DE TRABAJO ISSUES",
  ROL = "ROLES",
  ISS = "ISSUES",
  GPY = "GESTION DE PROYECTOS",
  INF = "INFORMES"
}

export enum ROLES_KEYS{
  MENU = "menu",
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public menu:Menu;
  public administrador:boolean=false;

  /**
   * 
   * @param httpService 
   * @param dummyService 
   */
  constructor( 
    private httpService:HttpService,
    private dummyService:DummyService) { 
      this.menu = null;
  }
  /**
   * 
   */
  async loadMenu(user:User):Promise<void>{
    //let request: IMenuRequest = this.httpService.getRequestMenu();
    let menu:IMenuResponse =  await this.httpService.doPostHttp<IMenuResponse>("/getmenu", this.httpService.getRequestMenu())
    console.log("menu",menu);
    localStorage.setItem("menu",JSON.stringify(menu));
    //localStorage.setItem(ROLES_KEYS.MENU,JSON.stringify(menu));
    this.buildMenu(menu,user);
  }
    /**
   * 
   */
    /*public get menuList():IMenuResponseItems[] | null{
      return JSON.parse(localStorage.getItem(ROLES_KEYS.MENU));
    }*/

  /**
   * 
   * @param menu 
   * @param user 
   */
  public buildMenu(menu:IMenuResponse, user:User){
    this.menu = new Menu(menu);
    this.menu.interface = this.getMenuByPermission(user);
  }


  /**
   * 
   * @param user 
   * @returns 
   */
  getMenuByPermission(user:User):NbMenuItem[]{
    let permissions = (user.decorator as ILoginResponse).permisos;
    let menuByPermission : IMenuResponseItems[] =  this.menu.items.filter((item:IMenuResponseItems)=>{
        return permissions.some((i:IloginResponsePermissions)=>i.id_menu === item.id)
    });
    let menu : NbMenuItem[] = [];

    let optionDashboard: NbMenuItem = {
      title:"Inicio",
      link:"/pages/site/dashboard"
    };

    menu.push(optionDashboard);

    for(let item of menuByPermission){
      let option: NbMenuItem = {
        title:"",
        children:[]
      };
      option.title = item.menu_descri;
      for(let permision of permissions){
        let create:boolean = (permision.id_menu === item.id) && (permision.crear === 1);
        let edit:boolean = (permision.id_menu === item.id) && (permision.editar === 1);
        let disable:boolean = (permision.id_menu === item.id) && (permision.inhabilitar === 1);
        let read:boolean = (permision.id_menu === item.id) && (permision.consultar === 1);
        let menuLinkView = "";
        let menuLinkCreate = "";
        if(permision.id_menu == 7 && (permision.consultar != 1  || permision.inhabilitar != 1)){
          this.administrador = true;
        }
        //console.log("swith",option.title);
        switch(option.title.toUpperCase()){
          case(NAME_MENU_ITEM.PROY):
            menuLinkCreate = "/pages/site/create-proyect";
            menuLinkView = "/pages/site/view-proyect";
          break;
          case(NAME_MENU_ITEM.USU):
            menuLinkCreate = "/pages/site/create-usuarios";
            menuLinkView = "/pages/site/view-usuarios";
          break;
          case(NAME_MENU_ITEM.ADM):
            menuLinkCreate = "/pages/site/create-adm-horas";
            menuLinkView = "/pages/site/view-adm-horas";
          break;  
          case(NAME_MENU_ITEM.CLI):
            menuLinkCreate = "/pages/site/create-clientes";
            menuLinkView = "/pages/site/view-clientes";
          break;       
          case(NAME_MENU_ITEM.ACT):
            menuLinkCreate = "/pages/site/create-actividad";
            menuLinkView = "/pages/site/view-actividad";
          break;  
          case(NAME_MENU_ITEM.REC):
            menuLinkCreate = "/pages/site/crear-recursos";
          break;  
          case(NAME_MENU_ITEM.ITE):
            menuLinkCreate = "/pages/site/create-iteracion";
            menuLinkView = "/pages/site/view-iteraciones";
          break;   
          case(NAME_MENU_ITEM.FLU):
            menuLinkCreate = "/pages/site/create-flujo";
            menuLinkView = "/pages/site/view-flujos";
          break;            
          case(NAME_MENU_ITEM.ROL):
            menuLinkCreate = "/pages/site/create-rol";
            menuLinkView = "/pages/site/view-roles";
          break; 
          case(NAME_MENU_ITEM.CRON):
            menuLinkCreate = "/pages/site/crear-cronograma";
            menuLinkView = "/pages/site/view-cronograma";
          break; 
          case(NAME_MENU_ITEM.GPY):
            menuLinkCreate = "/pages/site/gestion-proyectos";
          break; 
          case(NAME_MENU_ITEM.FER):
            menuLinkCreate = "/pages/site/crear-feriados";
            menuLinkView = "/pages/site/view-feriados";
          break; 
          case(NAME_MENU_ITEM.INF):
            menuLinkView = "/pages/site/view-informes";
            menuLinkCreate = "";
          break; 
          case(NAME_MENU_ITEM.ISS):
          menuLinkCreate = "/pages/site/create-issues";
          menuLinkView = "/pages/site/view-issues";
        break; 
          default:
            
          break;
        }

        if(read){
          option.children.push({
            title:"Ver / Administrar",
            link:menuLinkView
          });
          
          
        }
        if(create){
          option.children.push({
            title:"Crear",
            link:menuLinkCreate
          });
        }
      }
      menu.push(option);
    }
    console.log(menu);
   return menu;
  }
}