import { Injectable } from '@angular/core';
import { IRequestBase } from './structure-http-interface/base/IRequestBase';
import { ILoginRequest } from './structure-http-interface/ILogin/ILoginRequest';
import { AbstractHttpService } from './structure-http-interface/base/AbstractHttpService';
import { IMenuRequest } from './structure-http-interface/IMenu/IMenuRequest';
import { IResponseBase } from './structure-http-interface/base/IResponseBase';
import { IProyectRequest } from './structure-http-interface/IProyect/IProyectRequest';
import { ICreateProyectIRequest } from './structure-http-interface/ICreateProyectI/ICreateProyectIRequest';
import { ICreateProyectIVRequest } from './structure-http-interface/ICreateProyectIV/ICreateProyectIVRequest';
import { IEditProyectIRequest } from './structure-http-interface/IEditProyectI/IEditProyectIRequest';
import { ICreateProyectIIIRequest } from './structure-http-interface/ICreateProyectIII/ICreateProyectIIIRequest';
import { ICreateUserRequest } from './structure-http-interface/IUsuarios/ICreateUserRequest';
import { IRolesRequest } from './structure-http-interface/IRol/IRolesRequest';
import { IEditProyectIVRequest } from './structure-http-interface/IEditProyectIV/IEditProyectIVRequest';
import { IDeleteProyectIVRequest } from './structure-http-interface/IDeleteProyectIV/IDeleteProyectIVRequest';
import { ICountryRequest } from './structure-http-interface/ICountry/ICountryRequest';
import { IRegionRequest } from './structure-http-interface/IRegion/IRegionRequest';
import { IClientRequest } from './structure-http-interface/IClient/IClientRequest';
import { ICronogramaRequest } from './structure-http-interface/ICronograma/ICronogramaRequest';
import { IIteracionRequest } from './structure-http-interface/IIteracion/IIteracionResquest';
import { IGetIteracionesRequest } from './structure-http-interface/IIteracion/IGetIteracionesRequest';
import { IGetIssuesIteRequest } from './structure-http-interface/IIteracion/IGetIssuesIteRequest';
import { IGetClientesRequest } from './structure-http-interface/IClient/IGetClientesRequest';
import { ICreateRolRequest } from './structure-http-interface/IRol/ICreateRolRequest';
import { IGetEstadosRequest } from './structure-http-interface/IEstados/IGetEstadosRequest';
import { IGetTipoIssuesRequest } from './structure-http-interface/ITipoIssues/IGetTipoIssuesRequest';
import { ICreateFlujosRequest, ICreateFlujosDetRequest } from './structure-http-interface/IFlujos/ICreateFlujosRequest';
import { ICreatePermisosRequest } from './structure-http-interface/IRol/ICreatePermisosRequest';
import { IGetPermisosRequest } from './structure-http-interface/IRol/IGetPermisosRequest';
import { IGetIssuesPadresRequest } from './structure-http-interface/IIssues/IGetIssuesPadresRequest';
import { IGetEstadosSiguientesRequest } from './structure-http-interface/IIssues/IGetEstadosSiguientesRequest';
import { ICreateIssuesRequest } from './structure-http-interface/IIssues/ICreateIssuesRequest';
import { IGetComentariosRequest } from './structure-http-interface/IIssues/IGetComentariosRequest';
import { ICreateComentariosRequest } from './structure-http-interface/IIssues/ICreateComentariosRequest';
import { IGetHistorialRequest } from './structure-http-interface/IIssues/IGetHistorialRequest';
import { ICreateHorasRequest } from './structure-http-interface/IIssues/ICreateHorasRequest';
import { IGetHorasRequest } from './structure-http-interface/IIssues/IGetHorasRequest';
import { IUpdateIssueRequest } from './structure-http-interface/IIssues/IUpdateIssueRequest';
import { IGetIssuesRequest } from './structure-http-interface/IIssues/IGetIssuesRequest';
import { IMoverIssuesRequest } from './structure-http-interface/IIteracion/IMoverIssuesRequest';
import { IIniciarIteRequest } from './structure-http-interface/IIteracion/IIniciarIteRequest';
import { IInhaUsuarioRequest } from './structure-http-interface/IUsuarios/IInhaUsuarioRequest';
import { IInhaRolRequest } from './structure-http-interface/IRol/IInhaRolRequest';
import { IDeleteIssuesRequest } from './structure-http-interface/IIssues/IDeleteIssuesRequest';
import { IAprobRechaRequest } from './structure-http-interface/IIssues/IAprobRechaRequest';
import { IDeleteComentarioRequest } from './structure-http-interface/IIssues/IDeleteComentarioRequest';
import { ICorteCronogramaRequest } from './structure-http-interface/ICronograma/ICorteCronogramaRequest';
import { IGetCorteCronogramaRequest } from './structure-http-interface/ICronograma/IGetCorteCronogramaRequest';
import { IDeleteCorteCronogramaRequest } from './structure-http-interface/ICronograma/IDeleteCorteCronogramaRequest';
import { ICalcularFechasRequest } from './structure-http-interface/ICronograma/ICalcularFechasRequest';
import { IGetCronogramaResponse } from './structure-http-interface/ICronograma/IGetCronogramaResponse';
import { IGetCronogramaRequest } from './structure-http-interface/ICronograma/IGetCronogramaRequest';
import { IGetTareasHijasRequest } from './structure-http-interface/ICronograma/IGetTareasHijasRequest';
import { ICambiarEstatusRequest } from './structure-http-interface/ICronograma/ICambiarEstatusRequest';
import { IReplanificarCronogramaRequest } from './structure-http-interface/ICronograma/IReplanificarCronogramaRequest';
import { IMoverInicioCronogramaRequest } from './structure-http-interface/ICronograma/IMoverInicioCronogramaRequest';
import { IProyectoCronogramaRequest } from './structure-http-interface/ICronograma/IProyectoCronogramaRequest';
import { INoProyectoCronogramaRequest } from './structure-http-interface/ICronograma/INoProyectoCronogramaRequest';
import { ICreateAsociacionRequest } from './structure-http-interface/ICronograma/ICreateAsociacionRequest';
import { IDeleteCronogramaRequest } from './structure-http-interface/ICronograma/IDeleteCronogramaRequest';
import { IDeleteAlcanceRequest } from './structure-http-interface/ICronograma/IDeleteAlcanceRequest';
import { IEditCronogramaRequest } from './structure-http-interface/ICronograma/IEditCronogramaRequest';
import { IListadoCortesRequest } from './structure-http-interface/ICronograma/IListadoCortesRequest';
import { ICreateFeriadosAnioRequest } from './structure-http-interface/IProyect/ICreateFeriadosAnioRequest';
import { IGetFeriadosAnioRequest } from './structure-http-interface/IProyect/IGetFeriadosAnioRequest';
import { IDeleteFeriadoPaisRequest } from './structure-http-interface/IProyect/IDeleteFeriadoPaisRequest';
import { IEditClientRequest } from './structure-http-interface/IClient/IEditClientRequest';
import { IEditHorasRequest } from './structure-http-interface/IIssues/IEditHorasRequest';
import { IEditComentarioRequest } from './structure-http-interface/IIssues/IEditComentarioRequest';
import { IEditUsuarioRequest } from './structure-http-interface/IUsuarios/IEditUsuarioRequest';
import { IGetFlujoRequest } from './structure-http-interface/IFlujos/IGetFlujoRequest';
import { IAlcanceRequest } from './structure-http-interface/IAlcance/IAlcanceRequest';
import { IFeriadosRequest } from './structure-http-interface/IFeriados/IFeriadosRequest';
import { IGetConsIssuesRequest } from './structure-http-interface/IInformes/IGetConsIssuesRequest';
import { IGetRecursosRequest } from './structure-http-interface/IInformes/IGetRecursosRequest';
import { IGetEstadoReporteHorasRequest } from './structure-http-interface/IInformes/IGetEstadoReporteHorasRequest';
import { IGetDetIssuesRequest } from './structure-http-interface/IInformes/IGetDetIssuesRequest';
import { IGetReplanificacionRequest } from './structure-http-interface/IInformes/IGetReplanificacionRequest';
import { IDeleteProyectIIIRequest } from './structure-http-interface/IDeleteProyectIII/IDeleteProyectIIIRequest';
import { IEditProyectIIIRequest } from './structure-http-interface/IEditProyectIII/IEditProyectIIIRequest';
import { IEditRolesRequest } from './structure-http-interface/IRol/IEditRolesRequest';
import { IDeleteFlujoRequest } from './structure-http-interface/IFlujos/IDeleteFlujoRequest';
import { IGetMaxNumeroTareaRequest } from './structure-http-interface/ICronograma/IGetMaxNumeroTareaRequest';
import { IGetFlujoDetRequest } from './structure-http-interface/IFlujos/IGetFlujoDetRequest';
import { IAsignarRecursosRequest } from './structure-http-interface/IProyect/IAsignarRecursosRequest';
import { IDeleteRecurAsigRequest } from './structure-http-interface/IProyect/IDeleteRecurAsigRequest';
import { AuthService } from './auth.service';
import { Observable, Subscriber, Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HttpService extends AbstractHttpService {
  public AuthService:AuthService;
  public observerPetitions:Observable<boolean> = new Observable((subscriber)=>{
    this.subscriberPetitions = subscriber;
  });;
  public subscriberPetitions:Subscriber<boolean>;
  public subscriptionPetitions:Subscription;

  /**
   * 
   */
  constructor() {
    super();
  }



  /**
   * override
   * @param defaultHeaders 
   * @returns 
   */
  getRequestLogin(defaultHeaders: boolean = true): ILoginRequest {
    let request: ILoginRequest = (super.getRequestBase(defaultHeaders) as ILoginRequest);
    return request;
  };
  /** */
  protected async doHttp<T>(url:string,option:any):Promise<T>{
    try{
      if(this.subscriberPetitions != undefined){
        this.subscriberPetitions.next(false);
      }
      
      let response:any;
      let json = await fetch(url,option);
      response = await json.json();
      //console.log("responsehttp",response);
      if(response.error == "Se vencio la sesion"){
        this.AuthService.logout();
      }
      if(this.subscriberPetitions != undefined){
        this.subscriberPetitions.next(true);
      }
      return response;
    }catch(e){
      console.error('Error:', e);
      throw e;
    }
  }
  /**
   * override
   * @param defaultHeaders 
   * @returns 
   */
  getRequestIteraciones(defaultHeaders: boolean = true): IIteracionRequest {
    let request: IIteracionRequest = (super.getRequestBase(defaultHeaders) as IIteracionRequest);
    return request;
  };
  /**
   * override
   * @param defaultHeaders 
   * @returns 
   */
  getRequestMover(defaultHeaders: boolean = true): IMoverIssuesRequest {
    let request: IMoverIssuesRequest = (super.getRequestBase(defaultHeaders) as IMoverIssuesRequest);
    return request;
  };
  /**
  * override
  * @param defaultHeaders 
  * @returns 
  */
  getRequestClientesList(defaultHeaders: boolean = true): IGetClientesRequest {
    let request: IGetClientesRequest = (super.getRequestBase(defaultHeaders) as IGetClientesRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  editHoras(defaultHeaders: boolean = true): IEditHorasRequest {
    let request: IEditHorasRequest = (super.getRequestBase(defaultHeaders) as IEditHorasRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  editComentario(defaultHeaders: boolean = true): IEditComentarioRequest {
    let request: IEditComentarioRequest = (super.getRequestBase(defaultHeaders) as IEditComentarioRequest);
    return request;
  };
  /**
  * override
  * @param defaultHeaders 
  * @returns 
  */
  getRequestClientesEdit(defaultHeaders: boolean = true): IEditClientRequest {
    let request: IEditClientRequest = (super.getRequestBase(defaultHeaders) as IEditClientRequest);
    return request;
  };
  /**
  * override
  * @param defaultHeaders 
  * @returns 
  */
  getRequestPermisosList(defaultHeaders: boolean = true): IGetPermisosRequest {
    let request: IGetPermisosRequest = (super.getRequestBase(defaultHeaders) as IGetPermisosRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequestFlujosList(defaultHeaders: boolean = true): ICreateFlujosRequest {
    let request: ICreateFlujosRequest = (super.getRequestBase(defaultHeaders) as ICreateFlujosRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
getRequestFlujosDet(defaultHeaders: boolean = true): IGetFlujoDetRequest {
  let request: IGetFlujoDetRequest = (super.getRequestBase(defaultHeaders) as IGetFlujoDetRequest);
  return request;
};
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequestInhaUsuario(defaultHeaders: boolean = true): IInhaUsuarioRequest {
    let request: IInhaUsuarioRequest = (super.getRequestBase(defaultHeaders) as IInhaUsuarioRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequestInhaRol(defaultHeaders: boolean = true): IInhaRolRequest {
    let request: IInhaRolRequest = (super.getRequestBase(defaultHeaders) as IInhaRolRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequesPermisos(defaultHeaders: boolean = true): ICreatePermisosRequest {
    let request: ICreatePermisosRequest = (super.getRequestBase(defaultHeaders) as ICreatePermisosRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequestIteracionesList(defaultHeaders: boolean = true): IGetIteracionesRequest {
    let request: IGetIteracionesRequest = (super.getRequestBase(defaultHeaders) as IGetIteracionesRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequestFeriadoCreate(defaultHeaders: boolean = true): ICreateFeriadosAnioRequest {
    let request: ICreateFeriadosAnioRequest = (super.getRequestBase(defaultHeaders) as ICreateFeriadosAnioRequest);
    return request;
  };
  /**
* override
* @param defaultHeaders 
* @returns 
*/
  getRequestDeleteFeriadoPais(defaultHeaders: boolean = true): IDeleteFeriadoPaisRequest {
    let request: IDeleteFeriadoPaisRequest = (super.getRequestBase(defaultHeaders) as IDeleteFeriadoPaisRequest);
    return request;
  };
  /**
  * override
  * @param defaultHeaders 
  * @returns 
  */
  deleteRequestRecurAsig(defaultHeaders: boolean = true): IDeleteRecurAsigRequest {
    let request: IDeleteRecurAsigRequest = (super.getRequestBase(defaultHeaders) as IDeleteRecurAsigRequest);
    return request;
  };
  /* override
  * @param defaultHeaders 
  * @returns 
  */
  getRequestFeriadosList(defaultHeaders: boolean = true): IGetFeriadosAnioRequest {
    let request: IGetFeriadosAnioRequest = (super.getRequestBase(defaultHeaders) as IGetFeriadosAnioRequest);
    return request;
  };
  /**
  * override
  * @param defaultHeaders 
  * @returns 
  */
  getRequestIssuesIteList(defaultHeaders: boolean = true): IGetIssuesIteRequest {
    let request: IGetIssuesIteRequest = (super.getRequestBase(defaultHeaders) as IGetIssuesIteRequest);
    return request;
  };
  /**
   * override
   * @param defaultHeaders 
   * @returns 
   */
  getRequestClientes(defaultHeaders: boolean = true): IClientRequest {
    let request: IClientRequest = (super.getRequestBase(defaultHeaders) as IClientRequest);
    return request;
  };

  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestMenu(defaultHeaders: boolean = true): IMenuRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IMenuRequest);
    return request;
  }

  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestProyect(defaultHeaders: boolean = true): IProyectRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }
  getRequestCalendario(defaultHeaders: boolean = true): IGetClientesRequest {
    let request: IGetClientesRequest = (super.getRequestBase(defaultHeaders) as IGetClientesRequest);
    return request;
  }
  getRequestRecursosAsig(defaultHeaders: boolean = true): IGetRecursosRequest {
    let request: IGetRecursosRequest = (super.getRequestBase(defaultHeaders) as IGetRecursosRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestestados(defaultHeaders: boolean = true): IGetEstadosRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetEstadosRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestTipoIssues(defaultHeaders: boolean = true): IGetTipoIssuesRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetTipoIssuesRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestEstadoSiguientes(defaultHeaders: boolean = true): IGetEstadosSiguientesRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetEstadosSiguientesRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestComentarios(defaultHeaders: boolean = true): IGetComentariosRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetComentariosRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestHistorial(defaultHeaders: boolean = true): IGetHistorialRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetHistorialRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestHoras(defaultHeaders: boolean = true): IGetHorasRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetHorasRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestTareas(defaultHeaders: boolean = true): ICronogramaRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as ICronogramaRequest);
    return request;
  }
  /**
    * 
    * @param defaultHeaders 
    * @returns 
    */
  getRequestListadoCortes(defaultHeaders: boolean = true): IListadoCortesRequest {
    let request: IListadoCortesRequest = (super.getRequestBase(defaultHeaders) as IListadoCortesRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestRoles(defaultHeaders: boolean = true): IRolesRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IRolesRequest);
    return request;
  }

  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestPais(defaultHeaders: boolean = true): ICountryRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as ICountryRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestIssuesPadres(defaultHeaders: boolean = true): IGetIssuesPadresRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetIssuesPadresRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestIssuesHijas(defaultHeaders: boolean = true): IGetTareasHijasRequest {
    let request: IGetTareasHijasRequest = (super.getRequestBase(defaultHeaders) as IGetTareasHijasRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestIssues(defaultHeaders: boolean = true): IGetIssuesRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetIssuesRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestRegion(defaultHeaders: boolean = true): IRegionRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IRegionRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestCliente(defaultHeaders: boolean = true): IGetClientesRequest {
    let request: IMenuRequest = (super.getRequestBase(defaultHeaders) as IGetClientesRequest);
    return request;
  }
  /**
* 
* @param defaultHeaders 
* @returns 
*/
  editRequestUsuario(defaultHeaders: boolean = true): IEditUsuarioRequest {
    let request: IEditUsuarioRequest = (super.getRequestBase(defaultHeaders) as IEditUsuarioRequest);
    return request;
  }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getConsIssues(defaultHeaders: boolean = true): IGetConsIssuesRequest {
    let request: IGetConsIssuesRequest = (super.getRequestBase(defaultHeaders) as IGetConsIssuesRequest);
    return request;
  }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    getMaxNumero(defaultHeaders: boolean = true): IGetMaxNumeroTareaRequest {
      let request: IGetMaxNumeroTareaRequest = (super.getRequestBase(defaultHeaders) as IGetMaxNumeroTareaRequest);
      return request;
    }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    deleteFlujo(defaultHeaders: boolean = true): IDeleteFlujoRequest {
      let request: IDeleteFlujoRequest = (super.getRequestBase(defaultHeaders) as IDeleteFlujoRequest);
      return request;
    }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    editRoles(defaultHeaders: boolean = true): IEditRolesRequest {
      let request: IEditRolesRequest = (super.getRequestBase(defaultHeaders) as IEditRolesRequest);
      return request;
    }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    getReplanificaciones(defaultHeaders: boolean = true): IGetReplanificacionRequest {
      let request: IGetReplanificacionRequest = (super.getRequestBase(defaultHeaders) as IGetReplanificacionRequest);
      return request;
    }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    getDetIssues(defaultHeaders: boolean = true): IGetDetIssuesRequest {
      let request: IGetDetIssuesRequest = (super.getRequestBase(defaultHeaders) as IGetDetIssuesRequest);
      return request;
    }
  /**
* 
* @param defaultHeaders 
* @returns 
*/
  getRequestFlujo(defaultHeaders: boolean = true): IGetFlujoRequest {
    let request: IGetFlujoRequest = (super.getRequestBase(defaultHeaders) as IGetFlujoRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestCreateProyect(defaultHeaders: boolean = true): ICreateProyectIRequest {
    let request: ICreateProyectIRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }

  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestCreateissues(defaultHeaders: boolean = true): ICreateIssuesRequest {
    let request: ICreateIssuesRequest = (super.getRequestBase(defaultHeaders) as ICreateIssuesRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestCreatecomentario(defaultHeaders: boolean = true): ICreateComentariosRequest {
    let request: ICreateComentariosRequest = (super.getRequestBase(defaultHeaders) as ICreateComentariosRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  createRequestHoras(defaultHeaders: boolean = true): ICreateHorasRequest {
    let request: ICreateHorasRequest = (super.getRequestBase(defaultHeaders) as ICreateHorasRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  updateRequestIssues(defaultHeaders: boolean = true): IUpdateIssueRequest {
    let request: IUpdateIssueRequest = (super.getRequestBase(defaultHeaders) as IUpdateIssueRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestEditProyect(defaultHeaders: boolean = true): IEditProyectIRequest {
    let request: IEditProyectIRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }

  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestCreateProyectHolidays(defaultHeaders: boolean = true): ICreateProyectIRequest {
    let request: ICreateProyectIVRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }


  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestEditProyectHolidays(defaultHeaders: boolean = true): IEditProyectIVRequest {
    let request: IEditProyectIVRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }


   /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
   getRequestEditProyectScope(defaultHeaders: boolean = true): IEditProyectIIIRequest {
    let request: IEditProyectIIIRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }



  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestDeleteProyectHolidays(defaultHeaders: boolean = true): IDeleteProyectIVRequest {
    let request: IDeleteProyectIVRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }

   /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
   getRequestDeleteProyectScope(defaultHeaders: boolean = true): IDeleteProyectIVRequest {
    let request: IDeleteProyectIIIRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }


  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestCreateProyectEpic(defaultHeaders: boolean = true): ICreateProyectIIIRequest {
    let request: ICreateProyectIIIRequest = (super.getRequestBase(defaultHeaders) as IProyectRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestIniciarIte(defaultHeaders: boolean = true): IIniciarIteRequest {
    let request: IIniciarIteRequest = (super.getRequestBase(defaultHeaders) as IIniciarIteRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  createCalendario(defaultHeaders: boolean = true): IAsignarRecursosRequest {
    let request: IAsignarRecursosRequest = (super.getRequestBase(defaultHeaders) as IAsignarRecursosRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getRequestCreateRol(defaultHeaders: boolean = true): ICreateRolRequest {
    let request: ICreateRolRequest = (super.getRequestBase(defaultHeaders) as ICreateRolRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  deleteIssuesRequest(defaultHeaders: boolean = true): IDeleteIssuesRequest {
    let request: IDeleteIssuesRequest = (super.getRequestBase(defaultHeaders) as IDeleteIssuesRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  deleteComentarioRequest(defaultHeaders: boolean = true): IDeleteComentarioRequest {
    let request: IDeleteComentarioRequest = (super.getRequestBase(defaultHeaders) as IDeleteComentarioRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  AprobRechaRequest(defaultHeaders: boolean = true): IAprobRechaRequest {
    let request: IAprobRechaRequest = (super.getRequestBase(defaultHeaders) as IAprobRechaRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  CreateCronogramaRequest(defaultHeaders: boolean = true): ICronogramaRequest {
    let request: ICronogramaRequest = (super.getRequestBase(defaultHeaders) as ICronogramaRequest);
    return request;
  }
  /**
* 
* @param defaultHeaders 
* @returns 
*/
  EditCronogramaRequest(defaultHeaders: boolean = true): IEditCronogramaRequest {
    let request: IEditCronogramaRequest = (super.getRequestBase(defaultHeaders) as IEditCronogramaRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  CambiarEstatusCronogramaRequest(defaultHeaders: boolean = true): ICambiarEstatusRequest {
    let request: ICambiarEstatusRequest = (super.getRequestBase(defaultHeaders) as ICambiarEstatusRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  ReplanificarCronogramaRequest(defaultHeaders: boolean = true): IReplanificarCronogramaRequest {
    let request: IReplanificarCronogramaRequest = (super.getRequestBase(defaultHeaders) as IReplanificarCronogramaRequest);
    return request;
  }
  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  MoverInicioCronogramaRequest(defaultHeaders: boolean = true): IMoverInicioCronogramaRequest {
    let request: IMoverInicioCronogramaRequest = (super.getRequestBase(defaultHeaders) as IMoverInicioCronogramaRequest);
    return request;
  }
  /**
 * 
 * @param defaultHeaders 
 * @returns 
 */
  GetTareasAsoCronogramaRequest(defaultHeaders: boolean = true): IProyectoCronogramaRequest {
    let request: IProyectoCronogramaRequest = (super.getRequestBase(defaultHeaders) as IProyectoCronogramaRequest);
    return request;
  }
  /**
 * 
 * @param defaultHeaders 
 * @returns 
 */
  GetTareasNoAsoCronogramaRequest(defaultHeaders: boolean = true): INoProyectoCronogramaRequest {
    let request: INoProyectoCronogramaRequest = (super.getRequestBase(defaultHeaders) as INoProyectoCronogramaRequest);
    return request;
  }
  /**
* 
* @param defaultHeaders 
* @returns 
*/
  CreateAsociacionRequest(defaultHeaders: boolean = true): ICreateAsociacionRequest {
    let request: ICreateAsociacionRequest = (super.getRequestBase(defaultHeaders) as ICreateAsociacionRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  CorteCronogramaRequest(defaultHeaders: boolean = true): ICorteCronogramaRequest {
    let request: ICorteCronogramaRequest = (super.getRequestBase(defaultHeaders) as ICorteCronogramaRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  GetCorteCronogramaRequest(defaultHeaders: boolean = true): IGetCorteCronogramaRequest {
    let request: IGetCorteCronogramaRequest = (super.getRequestBase(defaultHeaders) as IGetCorteCronogramaRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  GetRecursosRequest(defaultHeaders: boolean = true): IGetRecursosRequest {
    let request: IGetRecursosRequest = (super.getRequestBase(defaultHeaders) as IGetRecursosRequest);
    return request;
  }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    GetHorasRecursosRequest(defaultHeaders: boolean = true): IGetHorasRequest{
      let request: IGetHorasRequest = (super.getRequestBase(defaultHeaders) as IGetHorasRequest);
      return request;
    }
    /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    GetReporteHorasRequest(defaultHeaders: boolean = true): IGetEstadoReporteHorasRequest{
      let request: IGetEstadoReporteHorasRequest = (super.getRequestBase(defaultHeaders) as IGetEstadoReporteHorasRequest);
      return request;
    }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  DeleteCorteCronogramaRequest(defaultHeaders: boolean = true): IDeleteCorteCronogramaRequest {
    let request: IDeleteCorteCronogramaRequest = (super.getRequestBase(defaultHeaders) as IDeleteCorteCronogramaRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  DeleteCronogramaRequest(defaultHeaders: boolean = true): IDeleteCronogramaRequest {
    let request: IDeleteCronogramaRequest = (super.getRequestBase(defaultHeaders) as IDeleteCronogramaRequest);
    return request;
  }
  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  DeleteAlcanceRequest(defaultHeaders: boolean = true): IDeleteAlcanceRequest {
    let request: IDeleteAlcanceRequest = (super.getRequestBase(defaultHeaders) as IDeleteAlcanceRequest);
    return request;
  }

  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
    CalcularFechasRequest(defaultHeaders: boolean = true): ICalcularFechasRequest {
      let request: ICalcularFechasRequest = (super.getRequestBase(defaultHeaders) as ICalcularFechasRequest);
      return request;
    }

  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestTareas2(defaultHeaders: boolean = true): IGetCronogramaRequest {
    let request: IGetCronogramaRequest = (super.getRequestBase(defaultHeaders) as IGetCronogramaRequest);
    return request;
  }

  /**
   * 
   * @param defaultHeaders 
   * @returns 
   */
  getRequestTareasHijas(defaultHeaders: boolean = true): IGetTareasHijasRequest {
    let request: IGetTareasHijasRequest = (super.getRequestBase(defaultHeaders) as IGetTareasHijasRequest);
    return request;
  }


  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getAlcanceRequest(defaultHeaders: boolean = true): IAlcanceRequest {
    let request: IAlcanceRequest = (super.getRequestBase(defaultHeaders) as IAlcanceRequest);
    return request;
  }


  /**
  * 
  * @param defaultHeaders 
  * @returns 
  */
  getFeriadosRequest(defaultHeaders: boolean = true): IFeriadosRequest {
    let request: IFeriadosRequest = (super.getRequestBase(defaultHeaders) as IFeriadosRequest);
    return request;
  }
  


  /**
   * 
   * @param headers 
   */
  loadHeaders(headers: [] | object): void {
    if (headers instanceof Array)
      for (let header of headers)
        this.request.headers.push(header);
    else
      this.request.headers.push(headers);
  }

}
